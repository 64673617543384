export function useStatus() {
  const isSold = (property: Sanity.Default.Schema.Properties) => {
    if (property
      && property.sale
      && (
        property.web_status === '2'
        || property.web_status === '3'
      )
    ) {
      return true
    }
    return false
  }
  const isUnderOffer = (property: Sanity.Default.Schema.Properties) => {
    if (property && property.sale && property.web_status === '1') {
      return true
    }
    return false
  }

  const isLetAgreed = (property: Sanity.Default.Schema.Properties) => {
    if (property
      && property.let
      && (
        property.web_status === '1'
        || property.web_status === '101'
        || property.web_status === '2'
        || property.web_status === '102'
        || property.web_status === '3'
        || property.web_status === '103'
        || property.web_status === '4'
        || property.web_status === '104'
      )
    ) {
      return true
    }
    return false
  }

  return {
    isSold,
    isUnderOffer,
    isLetAgreed,
  }
}
